import React, { useState, useRef } from "react";
import "./DashboardCommon.less";
import DeliveriesCommon from "../DeliveriesCommon/DeliveriesCommon";
import SearchContext from "../../context/SearchContext";
import { DeliveriesCommonProvider } from "../../context/DeliveriesCommonContext";

export const DashboardCommon = () => {
  const filters = useRef({});
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const value = {
    values,
    setValues,
    filters,
    loading,
    setLoading,
  };

  return (
    <div className="dashboard-common-wrapper">
      <div id="dashboard-common-container">
        <SearchContext.Provider value={value}>
          <DeliveriesCommonProvider>
            <DeliveriesCommon />
          </DeliveriesCommonProvider>
        </SearchContext.Provider>
      </div>
    </div>
  );
};
