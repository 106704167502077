import React from "react";
import "./RoutePlannerTable.less";
import RoutePlannerTableContent from "./RoutePlannerTableContent";
import DatePickerComponent from "./common/DatePickerComponent";
import SearchBarComponent from "./common/SearchBarComponent";
import useRoutePlannerData from "../../hooks/RoutePlanner/useRoutePlannerData";
import { Spin } from "antd";
import ManifestButton from "./common/ManifestButton";

export default function RoutePlannerTable({
  tab,
  heading,
  subheading,
  allowDatePicker,
  allowSearchBar,
  allowDownloadManifest,
}) {
  const {
    loading,
    error,
    data,
    getSelectedDateOrDefault,
    setSelectedDate,
    setSearchTerm,
    contextHolder,
    enableDownloadManifest,
  } = useRoutePlannerData(allowDatePicker, tab);

  return (
    <div className="routeplanner-view-table">
      <div className="routeplanner-view-table-header">
        <h3>{heading}</h3>
        <h4>{subheading}</h4>
        {allowDownloadManifest && (
          <div
            className="routeplanner-view-download-manifest-button"
            data-testid="rp-manifest-button"
          >
            <ManifestButton
              selectedDate={getSelectedDateOrDefault()}
              isEnabled={enableDownloadManifest}
              tab={tab}
              buttonName={"Download All Manifests"}
              isEnabledCSV={true}
              modalTitle={"Download All Manifests"}
            />
          </div>
        )}
      </div>
      <div className="routeplanner-view-table-query-section">
        {allowSearchBar && <SearchBarComponent setSearchTerm={setSearchTerm} />}
        {allowDatePicker && (
          <DatePickerComponent setSelectedDate={setSelectedDate} />
        )}
      </div>

      <div className="routeplanner-view-table-content">
        {loading && (
          <div className="rp-spinner" data-testid="rp-spinner">
            <Spin size="large" />
          </div>
        )}
        {contextHolder}
        <RoutePlannerTableContent
          tab={tab}
          data={data}
          selectedDate={getSelectedDateOrDefault()}
          error={error}
          loading={loading}
        />
      </div>
    </div>
  );
}
