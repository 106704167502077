import React, { useState, useContext } from "react";
import Button from "antd/es/button";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import moment from "moment";
import "./WeeklyCalendar.less";
import buildCalendar from "./BuildCalendar";
import permit from "../../../util/Permissions";
import { UserContext } from "../../../context/RootContext";

const WeeklyCalendar = ({
  toggleSubmit,
  updateSelectDate,
  updateSelectDateIndex,
  updateSelectedDateSlotsCount,
  slotsByDate,
}) => {
  const { user } = useContext(UserContext);
  const [value, setValue] = useState(
    moment(new Date(Object.keys(slotsByDate)[0])).locale("en")
  );
  const [selectDate, setSelectDate] = useState();

  function isDeliverySlots(day) {
    return (
      slotsByDate[day.format("YYYY-MM-DD")] !== undefined &&
      moment(new Date()).isSameOrBefore(day, "day")
    );
  }

  function slotsOn(day) {
    return slotsByDate[day.format("YYYY-MM-DD")];
  }

  function dayStyles(day) {
    let dayClassName = "";

    if (moment(new Date()).isSame(day, "day")) {
      dayClassName = dayClassName ? `${dayClassName} today` : "today";
    }

    if (isDeliverySlots(day)) {
      dayClassName = dayClassName ? `${dayClassName} available` : "available";
    } else {
      dayClassName = dayClassName ? `${dayClassName} before` : "before";
    }

    if (onClickProducerByDay(day)) {
      dayClassName = dayClassName ? `${dayClassName} pointer` : "pointer";
    }

    if (selectDate && selectDate.isSame(day, "day")) {
      dayClassName = dayClassName ? `${dayClassName} selected` : "selected";
    }

    return dayClassName;
  }

  function onClickProducerByDay(day, dayIndex) {
    const callback = () => {
      setValue(day);
      setSelectDate(day);
      updateSelectDate(day);
      updateSelectDateIndex(dayIndex);
      updateSelectedDateSlotsCount(slotsOn(day) || 0);
      toggleSubmit(true);
    };
    if (isDeliverySlots(day)) {
      if (permit("applyMONoOverrideFilter", user)) {
        if (slotsOn(day)) {
          return callback;
        }
        return null;
      } else {
        return callback;
      }
    }
    return null;
  }

  function getSlotsClass(day) {
    let str = "availability ";
    if (permit("applyMONoOverrideFilter", user)) {
      if (slotsOn(day)) {
        str = `${str} + nonZero`;
      } else {
        str = `${str} + unAvailable`;
      }
    } else {
      if (slotsOn(day) === undefined) {
        str = `${str} + unAvailable`;
      } else if (slotsOn(day) !== 0) {
        str = `${str} + nonZero`;
      } else {
        str = `${str} + zero`;
      }
    }
    return str;
  }

  return (
    <div className="calendar">
      <div className="header">
        <div
          className="previous"
          onClick={() =>
            !value.isSame(new Date(), "week") &&
            setValue(value.clone().subtract(1, "week"))
          }
        >
          <Button type="link">
            <LeftOutlined className="left-arrow" />
          </Button>
        </div>
        <div className="available-week">
          {value.isSame(new Date(Object.keys(slotsByDate)[0]), "week")
            ? "First Available Week, "
            : ""}
          {value.clone().startOf("day").startOf("week").format("MMM DD ")}-
          {value.clone().endOf("week").endOf("week").format(" MMM DD")}
        </div>
        <div
          className="next"
          onClick={() => setValue(value.clone().add(1, "week"))}
        >
          <Button type="link">
            <RightOutlined className="right-arrow" />
          </Button>
        </div>
      </div>
      <div className="calendar-body-wrapper">
        <div className="body">
          {buildCalendar(value).map((week, weekIndex) => (
            <div key={weekIndex}>
              {week.map((day, dayIndex) => (
                <div
                  className="day"
                  key={dayIndex}
                  onClick={onClickProducerByDay(day, dayIndex)}
                >
                  <div className="day-of-the-week">
                    {day.format("dddd").toString()}
                  </div>
                  <div className={dayStyles(day)}>
                    <div className="month">{day.format("MMMM").toString()}</div>
                    <div className="date">{day.format("D").toString()}</div>
                    <div className={getSlotsClass(day)}>
                      {slotsOn(day) === undefined
                        ? "unavailable"
                        : slotsOn(day) !== 0
                        ? `${slotsOn(day)} slots`
                        : "0 slots"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyCalendar;
