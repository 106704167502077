import React, { useContext, useEffect } from "react";
import { Pagination, Row } from "antd";
import Search from "../Search/Search";
import Loading from "../Loading/Loading";
import LocationContext from "../../context/LocationContext";
import ResultTable from "./ResultTable/ResultTable";
import "./DeliveriesCommon.less";
import useSearchResult from "../../hooks/deliverySearchResultsHook";
import DeliveriesCommonContext from "../../context/DeliveriesCommonContext";
import useNotification from "../../hooks/useNotification";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook";
import {
  PAGINATION_CHANGE_PAGE_EVENT,
  SEARCH_RESULTS_EVENT,
} from "../../util/NewRelicConstants";
import DashboardContext from "../../context/DashboardContext";
import { DASHBOARD_STH_CONTEXT } from "../../util/Constants";
import { useSearchStateHook } from "../../hooks/useSearchStateHook";
import {
  pluckFilters,
  FILTER_KEYS,
  sanitizer,
  inputValidation,
} from "../Search/search.helper";
import SearchContext from "../../context/SearchContext";

export default function DeliveriesCommon() {
  const { currentLocation } = useContext(LocationContext);
  const { searchResult } = useContext(DeliveriesCommonContext);
  const { loading, setLoading } = useContext(SearchContext);

  const { persistedValues, update } = useSearchStateHook(
    {
      type: "",
      input: "",
      optionalInput: "",
      searchAppliances: true,
      currentPage: 0,
      pageNumber: 1,
      pageSize: 100,
    },
    { sanitizer }
  );
  const paginationData = {
    currentPage: persistedValues.currentPage || 0,
    pageNumber: persistedValues.pageNumber || 1,
    pageSize: persistedValues.pageSize || 100,
  };

  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const { getInitSearchResult } = useSearchResult();
  const { openSuccessNotification, contextHolder } = useNotification();
  const dashboardContext = useContext(DashboardContext);

  const isValidInput = inputValidation(persistedValues);

  useEffect(() => {
    if (currentLocation && isValidInput) {
      const filters = pluckFilters(FILTER_KEYS, persistedValues);
      executeSearchCall(() =>
        getInitSearchResult(
          paginationData,
          loading,
          setLoading,
          {
            ...persistedValues,
          },
          filters
        )
      );
    }
  }, [currentLocation, JSON.stringify(persistedValues)]);

  function getTotalCount(data) {
    if (dashboardContext === DASHBOARD_STH_CONTEXT) {
      return data.sthDeliveries.length;
    }
    return data.total;
  }

  const executeSearchCall = async (eventCallback) => {
    const start = performance.now();
    const responseData = await eventCallback();
    const duration = performance.now() - start;
    const formattedDuration = duration ? Number(duration.toFixed(2)) : 0;
    const totalCount =
      responseData && responseData.data && !responseData.error
        ? getTotalCount(responseData.data)
        : 0;

    logNewRelicMetricsEvent(SEARCH_RESULTS_EVENT, {
      count: totalCount,
      responseTime: formattedDuration,
      searchGroup: persistedValues.type,
      searchTerm: persistedValues.input,
    });
  };

  function renderSearchResults() {
    if (!isValidInput) {
      return (
        <div id="zero-results">
          Please use the search or date filter above to begin
        </div>
      );
    } else {
      return (
        <div>
          {searchResult &&
            searchResult.total &&
            searchResult.total > 10 &&
            renderPagination()}
          <ResultTable
            data={searchResult}
            sthRefreshDeliveries={sthRefreshDeliveriesAfterReschedule}
          />
        </div>
      );
    }
  }

  function handlePageChange(page, pageSize) {
    const currentPage = (Number(page) - 1) * Number(pageSize);
    logNewRelicMetricsEvent(PAGINATION_CHANGE_PAGE_EVENT, {
      text: `from=${currentPage}&size=${pageSize}`,
    });

    update(
      {
        currentPage,
        pageNumber: page,
        pageSize,
      },
      { persist: true }
    );
  }

  const getPageSizeOptions = () => {
    const pageSizeOption = [];
    const defaultPageSizeOption = ["10", "20", "50", "100"];
    for (let i = 0; i < defaultPageSizeOption.length; i++) {
      if (Number(defaultPageSizeOption[i]) >= searchResult.total) {
        break;
      }
      pageSizeOption.push(defaultPageSizeOption[i]);
    }
    return pageSizeOption;
  };

  function renderPagination() {
    const pageNumber = parseInt(paginationData.pageNumber, 10);
    return (
      <Row justify="end">
        <Pagination
          current={pageNumber}
          total={searchResult && searchResult.total}
          showSizeChanger={getPageSizeOptions().length > 1}
          pageSizeOptions={getPageSizeOptions()}
          showTotal={(total, range) =>
            `${range[0]} - ${range[1]} of ${total} items`
          }
          pageSize={paginationData.pageSize}
          onChange={handlePageChange}
        />
      </Row>
    );
  }

  async function sthRefreshDeliveriesAfterReschedule(
    workOrder,
    currentDeliveryDate,
    deliveryRescheduledToDate
  ) {
    const filters = pluckFilters(FILTER_KEYS, persistedValues);

    await getInitSearchResult(
      paginationData,
      loading,
      setLoading,
      { ...persistedValues },
      filters
    );

    openSuccessNotification({
      message: "Delivery Reschedule",
      description: `${workOrder} was rescheduled from ${currentDeliveryDate} to ${deliveryRescheduledToDate}`,
    });
  }

  return (
    <div id={"searchResults"}>
      {contextHolder}
      {loading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
      <Search />
      <div>{!loading && renderSearchResults()}</div>
    </div>
  );
}
