import { useRef, useState, useEffect } from "react";
import axios from "axios";
import * as newRelicConstants from "../util/NewRelicConstants";
import getLogoutUrl from "../util/getLogoutUrl";
import useNewRelicMetrics from "../hooks/newRelicMetricsHook";

/**
 * Mapping of URL paths to their corresponding event types.
 */
const URL_PATH = {
  "/": "dashboard",
  "/dca": "dca",
  "/dashboard-sth": "dashboard-sth",
  "/dashboard-common": "dashboard-beta",
  "/workbin": "workbin",
  "/mail": "Mail",
  "/route-planner/routes/*": "route-planner-view",
};

/**
 * Custom hook for handling authentication and access control.
 *
 * This hook sets up an axios interceptor to handle 401 and 403 errors,
 * logs New Relic metrics for 401 errors, and manages the noAccess state for 403 errors.
 *
 * @returns {Object} An object containing the noAccess state and its setter function.
 */
export const useAuthInterceptor = () => {
  const { pathname } = window.location;
  const { logNewRelicMetricsEvent, noticeError } = useNewRelicMetrics();
  const [noAccess, setNoAccess] = useState(false);
  const interceptorRef = useRef();

  function getEventTypePath() {
    for (const key in URL_PATH) {
      if (key.endsWith("*")) {
        const baseKey = key.slice(0, -1);
        if (pathname.startsWith(baseKey)) {
          return URL_PATH[key];
        }
      } else if (pathname === key) {
        return URL_PATH[key];
      }
    }
  }

  function getEventType() {
    const eventTypePath = getEventTypePath();
    return newRelicConstants.ERROR_LOGOUT_EVENT.replace("?", eventTypePath);
  }

  function serialize(params) {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  }

  if (!interceptorRef.current) {
    interceptorRef.current = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // we do auto logout in case any api call returns 'unauthorized' http status code (401 code)
        if (error.response.status === 401) {
          try {
            const errorInfo = {
              text: error.stack,
              error_message: `${error.message}, status text: ${error.response.statusText}`,
              error_description:
                error.config.url + serialize(error.config.params),
              error_status: error.response.status,
            };
            logNewRelicMetricsEvent(getEventType(), errorInfo);
            noticeError(error);
          } catch (e) {
            console.error("Error handling interceptor response:", e);
          }
          window.location.href = getLogoutUrl();
        }
        // we redirect to access request page in case any api call returns 'forbidden' http status code (403 code)
        else if (error.response.status === 403) {
          setNoAccess(true);
        }
        return Promise.reject(error);
      }
    );
  }

  return { noAccess, setNoAccess };
};
