import React, { useMemo } from "react";
import { Link, Outlet, useMatch } from "react-router-dom";
import { Menu } from "antd";
import "./RoutePlannerView.less";
import useLocationChangeReset from "../../hooks/RoutePlanner/useLocationChangeReset";

const routes = [
  { path: "planning", label: "Planning" },
  { path: "dispatch", label: "Dispatch" },
  { path: "unassigned", label: "Unassigned Bin" },
  { path: "archive", label: "Archive" },
];

export default function RoutePlannerView() {
  const match = useMatch("/route-planner/routes/*");
  useLocationChangeReset();

  const getSelectedKey = useMemo(() => {
    const path = match.pathname.toLowerCase();
    const selectedRoute = routes.find((route) =>
      path.includes(`/${route.path}`)
    );

    return selectedRoute ? selectedRoute.path : "planning";
  }, [match]);

  return (
    <div key={getSelectedKey} id="routeplanner-view-container">
      <Menu mode="horizontal" selectedKeys={[getSelectedKey]}>
        {routes.map((route) => (
          <Menu.Item key={route.path}>
            <Link to={`${route.path}`}>{route.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
      <Outlet />
    </div>
  );
}
