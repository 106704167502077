/**
 * @fileoverview Utility functions for constructing URLs for the Route Planner API.
 */

import getDrdsBffUrl from "./getDrdsBffUrl";

/**
 * Configuration object for the Route Planner API.
 * @type {Object}
 * @property {string} BASE_URL - The base URL of the API.
 * @property {Object} ENDPOINTS - The endpoints of the API.
 * @property {string} ENDPOINTS.ROUTE_SUMMARY - The endpoint for route summary.
 * @property {string} ENDPOINTS.ROUTE_DETAILS - The endpoint for route details.
 * @property {string} ENDPOINTS.UNASSIGNED_STOPS - The endpoint for unassigned stops.
 * @property {string} ENDPOINTS.ARCHIVE_ROUTE_DETAILS - The endpoint for archive route details.
 */
export const API_CONFIG = {
  BASE_URL: "/v1/routeInfo",
  ENDPOINTS: {
    ROUTE_SUMMARY: "/summary",
    ROUTE_DETAILS: "/detail",
    UNASSIGNED_STOPS: "/unassigned",
    ARCHIVE_ROUTE_DETAILS: "/archive",
    FIELD_DATA: "/fieldData",
    MANIFEST_URL: "/routeManifest",
  },
};

/**
 * Constructs the URL for the route summary API endpoint.
 * @param {string} tab - The tab parameter for the route summary.
 * @param {string} location - The location parameter for the route summary.
 * @param {string} date - The date parameter for the route summary.
 * @returns {string} The constructed URL for the route summary API endpoint.
 * @example
 * const routeSummaryUrl = getRouteSummaryUrl("planning", "123", "2023-05-17");
 * // Output: "/rp-api/v1/routeInfo/summary?tab=planning&location=123&date=2023-05-17"
 */
export const getRouteSummaryUrl = (tab, location, date) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.ROUTE_SUMMARY
  }?tab=${tab}&location=${location}&date=${date}`;
};

/**
 * Constructs the URL for the route details API endpoint.
 * @param {string} tab - The tab parameter for the route summary.
 * @param {string} location - The location parameter for the route summary.
 * @param {string} routeId - The routeId parameter for the route details.
 * @returns {string} The constructed URL for the route details API endpoint.
 * @example
 * const routeDetailsUrl = getRouteDetailsUrl("planning", "123");
 * // Output: "/rp-api/v1/routeInfo/detail?tab=planning&routeId=123"
 */
export const getRouteDetailsUrl = (tab, location, routeId) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.ROUTE_DETAILS
  }?tab=${tab}&location=${location}&routeId=${routeId}`;
};

/**
 * Constructs the URL for the unassigned stops API endpoint.
 * @param {string} location - The location parameter for the route unassigned stops.
 * @param {string} date - The date parameter for the route unassigned stops.
 * @returns {string} The constructed URL for the unassigned stops API endpoint.
 * @example
 * const unassignedStopsUrl = getUnassignedStopsUrl("5925", "2024-04-29");
 * // Output: "/rp-api/v1/routeInfo/unassigned?location=5925&date=2024-04-29"
 */
export const getUnassignedStopsUrl = (location, date) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.UNASSIGNED_STOPS
  }?location=${location}&date=${date}`;
};

export const getArchiveUrl = (location, searchParam, searchTerm) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.ARCHIVE_ROUTE_DETAILS
  }?location=${location}&${searchParam}=${searchTerm}`;
};

/**
 * Constructs the URL for the fieldData API endpoint.
 */
export const getStopFieldDataUrl = (stopID) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.FIELD_DATA
  }?stopId=${stopID}`;
};

export const getRouteManifestUrl = (location, tab, selectedDate) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.MANIFEST_URL
  }?tab=${tab}&location=${location}&date=${selectedDate}`;
};

export const getRouteManifestDetailsUrl = (
  location,
  tab,
  selectedDate,
  routeId
) => {
  return `${getDrdsBffUrl()}${API_CONFIG.BASE_URL}${
    API_CONFIG.ENDPOINTS.MANIFEST_URL
  }?tab=${tab}&location=${location}&date=${selectedDate}&routeId=${routeId}`;
};
