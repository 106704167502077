import React from "react";
import "./RouteDetailsView.less";
import { Spin, Table } from "antd";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import { useTableConfig } from "../../hooks/RoutePlanner/useTableConfig";
import DriverNotesDrawer from "./DriverNotesDrawer";
import { useRouteDetailsData } from "../../hooks/RoutePlanner/useRouteDetailsData";
import ManifestButton from "./common/ManifestButton";
import { getRPRowClassName } from "../../util/RoutePlanner/rpTableConfigUtils";

export default function RouteDetailsView({ heading, subheading, tab }) {
  const match = useMatch("/route-planner/*");
  const resolvedPath = useResolvedPath(match.pathname);
  const { loading, error, stopsData, driverData, contextHolder } =
    useRouteDetailsData(tab);
  const {
    columns,
    handleTableChange,
    isDrawerOpen,
    setIsDrawerOpen,
    selectedStopId,
    driverNotesWorkOrderNumber,
    driverNotesOrderNumber,
  } = useTableConfig(stopsData, tab, resolvedPath.pathname, "details");

  const location = useLocation();
  const previousUrlParams = location.state?.prevQueryParams ?? null;
  const isFromDashboard = location.state?.fromDashboard ?? false;

  return (
    <div className="routedetail-view-table">
      <div className="routedetail-view-table-header">
        <div
          className="routedetail-view-table-header-back-arrow"
          data-testid="rp-details-back-arrow"
        >
          <Link
            to={{
              pathname: `${resolvedPath.pathname.substring(
                0,
                resolvedPath.pathname.indexOf("/details")
              )}`,
              search: isFromDashboard ? previousUrlParams : null,
            }}
          >
            <img
              src="/images/backArrow.svg"
              alt={"back"}
              style={{ width: "25px", height: "25px", alignSelf: "center" }}
            />
          </Link>
        </div>
        <h3>{heading}</h3> <h4>{subheading}</h4>
        {
          <div className="routedetail-view-download-manifest-button">
            <ManifestButton
              routeId={driverData.routeId}
              isEnabled={true}
              selectedDate={driverData.date}
              tab={tab}
              buttonName={"Download Manifest"}
              isEnabledCSV={false}
              modalTitle={"Download Route Manifest"}
            />
          </div>
        }
      </div>
      <br />

      {loading && (
        <div className="rp-details-spinner" data-testid="rp-details-spinner">
          <Spin size="large" />
        </div>
      )}
      {error && (
        <div>
          <span className={""}></span>
        </div>
      )}
      {contextHolder}
      <div className="routedetail-view-table-query-section">
        <table className="routedetail-view-table-query-driverinfo-table">
          <tbody>
            <tr className="routedetail-view-table-row">
              <th colSpan="2" style={{ padding: "8px" }}>
                Driver Information{" "}
              </th>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left">
                Name: <b>{driverData.driverName}</b>
              </td>
              <td className="routedetail-view-table-column-right">
                Carrier: <b>{driverData.carrierName}</b>
              </td>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left">
                Driver Key: <b>{driverData.driverId}</b>
              </td>
              <td className="routedetail-view-table-column-right">
                Truck ID: <b>{driverData.truckId}</b>
              </td>
            </tr>
            <tr>
              <td className="routedetail-view-table-column-left"></td>
              <td className="routedetail-view-table-column-right">
                Route ID: <b>{driverData.routeId}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="routedetail-view-table-content">
        <Table
          columns={columns}
          tableLayout="auto"
          dataSource={stopsData}
          onChange={handleTableChange}
          rowClassName={getRPRowClassName}
          pagination={false}
          size={"middle"}
        />
      </div>
      {isDrawerOpen && (
        <DriverNotesDrawer
          isDrawerOpen={isDrawerOpen}
          setDrawerOpen={setIsDrawerOpen}
          selectedStopId={selectedStopId}
          driverNotesWorkOrderNumber={driverNotesWorkOrderNumber}
          driverNotesOrderNumber={driverNotesOrderNumber}
        />
      )}
    </div>
  );
}
