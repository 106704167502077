import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useUrlData } from "../../../hooks/RoutePlanner/useUrlData";

/**
 * A reusable DatePicker component that allows selecting a date and syncs with the URL query parameters.
 */
export default function DatePickerComponent({
  setSelectedDate,
  dateFormat = "YYYY-MM-DD",
}) {
  const { getQueryParam, setQueryParam, deleteQueryParam, getCurrentTab } =
    useUrlData();
  const dateDefaultValue = getQueryParam("date");

  const onDateChange = (date) => {
    if (date) {
      const formattedDate = date.format(dateFormat);
      setSelectedDate(formattedDate);
      setQueryParam("date", formattedDate);
    } else {
      deleteQueryParam("date");
    }
  };

  return (
    <DatePicker
      size="large"
      format={dateFormat}
      placeholder={"Select Date"}
      value={setDateValueFromQueryString(dateDefaultValue, dateFormat)}
      onChange={onDateChange}
      disabledDate={isDateDisabled(getCurrentTab())}
    />
  );
}

function setDateValueFromQueryString(dateDefaultValue, dateFormat) {
  return dateDefaultValue ? moment(dateDefaultValue, dateFormat) : null;
}

function isDateDisabled(path) {
  return (current) => {
    if (path === "planning") {
      return current < moment().endOf("day");
    }
    return false;
  };
}
