export function getOptions(override, matchObject) {
  switch (typeof override) {
    case "object":
      return override;
    case "string":
      return DATA[override] === undefined ? [] : DATA[override];
    default:
      switch (matchObject.pathname) {
        case "/route-planner/routes/archive":
          return DATA.archive;
        case "/route-planner/routes/planning":
          return DATA.planning;
        case "/route-planner/routes/dispatch":
          return DATA.dispatch;
        case "/route-planner/routes/unassigned":
          return DATA.unassigned;
        default:
          return [
            {
              displayName: "Invalid",
              value: "default",
              placeholder: "Invalid",
              validationString: "(?!.*)",
            },
          ];
      }
  }
}

/**
 * How to use:
 * Edit cases above to modify the options to return for each route
 * Every options list entry must have an entry with "default" as the value
 * The displayName is what's shown in the UI
 * The placeholder is what's shown in the input field
 */
const DATA = {
  archive: [
    {
      displayName: "Search by",
      value: "default",
      placeholder: "Type here to search",
      validationString: "(?!.*)",
    },
    {
      displayName: "Order number",
      value: "orderNumber",
      placeholder: "ex H0121-127156, W123456789, C123456789",
      validationString: "^([Hh][0-9]*-[0-9]*)$|^([WwCc][0-9a-zA-Z][0-9]*)$",
    },
    {
      displayName: "Customer last name",
      value: "customerLastName",
      placeholder: "ex Smith, Jones",
      validationString: "^[^%\\\\'\"]+$",
    },
    {
      displayName: "MSN",
      value: "msNumber",
      placeholder: "ex 1AHV18L8NX",
      validationString: "^[a-z0-9]{10}$",
    },
  ],
  dispatch: [
    {
      displayName: "Search by",
      value: "default",
      placeholder: "Type here to search",
      validationString: "(?!.*)",
    },
  ],
  planning: [
    {
      displayName: "Search by",
      value: "default",
      placeholder: "Type here to search",
      validationString: "(?!.*)",
    },
  ],
  unassigned: [
    {
      displayName: "Search by",
      value: "default",
      placeholder: "Type here to search",
      validationString: "(?!.*)",
    },
  ],
};
