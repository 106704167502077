import React, { useState, useContext, useEffect } from "react";
import "./ManualOrder.less";
import CustomerInfo from "../CustomerInfo/CustomerInfo";
import { Input, Form, Button, Row, Col } from "antd";
import OrderContext from "../../context/OrderContext";
import MOScheduleDate from "./MOScheduleDate/MOScheduleDate";
import ProductOrders from "./ProductOrders/ProductOrders.js";
import * as constants from "../../util/Constants";
import { showNotification } from "../../util/notification";
import CreateManualOrderHook from "../../hooks/createManualOrderHook";
import DeliveriesHook from "../../hooks/deliveriesHook";
import { determineManuallyCode } from "../../util/DetermineManuallyCode";
import useNewRelicMetrics from "../../hooks/newRelicMetricsHook.js";
import {
  REVISIT_CANCEL_EVENT,
  REVISIT_NEXT_EVENT,
  REVISIT_SUBMIT_EVENT,
  OVERLAY_TYPE_REVISIT_LEGACY,
  REVISIT_SUBMIT_ERROR,
} from "../../util/NewRelicConstants.js";

const ManualOrder = ({
  orderDetails,
  getStatusHistory,
  revisitType,
  orderIndex,
}) => {
  const { TextArea } = Input;
  const { toggleManualOrder } = useContext(OrderContext);
  const [selectDate, updateSelectDate] = useState({});
  const [selectDateIndex, updateSelectDateIndex] = useState();
  const [selectedDateSlotsCount, updateSelectedDateSlotsCount] = useState();

  const {
    deliverySlotsState,
    getDeliverySlots,
    slotsByDate,
    cleanDeliverySlots,
  } = DeliveriesHook();
  const { createManualOrder, cleanCreateManualOrder, createManualOrderState } =
    CreateManualOrderHook();
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();
  const [, setFieldValue] = useState({});
  const [addNotes, setAddNotes] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [enabledVendor, setEnabledVendor] = useState(null);
  const [slide, setSlide] = useState(0);
  const [form] = Form.useForm();

  const notes = form.getFieldValue("notes");
  const lineItems = form.getFieldValue("lineItems");
  const modelNumberList = form.getFieldValue("lineItems")
    ? form.getFieldValue("lineItems").map((v) => v.split("__")[0])
    : setModelDetails();

  function collectSelectedLineItems() {
    const arrayOfLineItems = [];
    const lineNumbers = [];
    if (modelNumberList) {
      let pushItem = true;
      modelNumberList.map((modelNumber) => {
        //TODO: this might need to be a for loop
        orderDetails.deliveryDetail.poDetailList[
          enabledVendor
        ].lineLevelDetailList.forEach((itemList) => {
          if (
            pushItem &&
            modelNumber === itemList.modelNbr &&
            !lineNumbers.includes(itemList.lineNbr)
          ) {
            arrayOfLineItems.push(itemList);
            lineNumbers.push(itemList.lineNbr);
            pushItem = false;
          }
        });
        pushItem = true;
      });
    }
    return arrayOfLineItems;
  }

  function setVendorDetails() {
    if (orderDetails.customerDetail.custOrdId === "") {
      setEnabledVendor(0);
      setSelectedVendor(orderDetails.deliveryDetail.poDetailList[0].vendorName);
    }
  }

  function setModelDetails() {
    if (slide === 0) {
      return null;
    }
    if (slide === 1 && orderDetails.customerDetail.custOrdId === "") {
      const model = [];
      model.push(constants.REVISIT_MODEL_NUMBER);
      return model;
    }
  }

  function isNewManualOrder() {
    // CustomerOrderID will be empty only when original order is created from 'New Manual Order' flow
    if (orderDetails.customerDetail.custOrdId === "") {
      return true;
    }
    return false;
  }

  const extractOrderData = (orderState) => {
    let message = "";
    let error = false;

    if (orderState && orderState.data) {
      const { newMSNumber } = orderState.data;
      const isValidMSNumber = new RegExp("^[a-zA-Z0-9]{10}$", "i").test(
        newMSNumber
      );
      message = `Manual order has been successfully created with MS# ${
        isValidMSNumber ? newMSNumber : "---"
      }`;
    } else if (orderState && orderState.error) {
      message = "Manual order creation failed";
      error = true;
    }

    return { message, error };
  };

  useEffect(() => {
    if (
      (createManualOrderState && createManualOrderState.data) ||
      (createManualOrderState && createManualOrderState.error)
    ) {
      const { message, error } = extractOrderData(createManualOrderState);
      if (error) {
        logNewRelicMetricsEvent(REVISIT_SUBMIT_ERROR, {
          order_number: orderDetails.customerDetail.custOrdId,
          index: orderIndex,
          text: revisitType,
          selected_date_slot_count: selectedDateSlotsCount,
          calendar_index: selectDateIndex,
          overlay_type: OVERLAY_TYPE_REVISIT_LEGACY,
        });
      } else {
        logNewRelicMetricsEvent(REVISIT_SUBMIT_EVENT, {
          order_number: orderDetails.customerDetail.custOrdId,
          index: orderIndex,
          text: revisitType,
          selected_date_slot_count: selectedDateSlotsCount,
          calendar_index: selectDateIndex,
          overlay_type: OVERLAY_TYPE_REVISIT_LEGACY,
        });
      }
      cleanCreateManualOrder();
      cleanDeliverySlots();
      showNotification(message, error);
      toggleManualOrder(false);
    }
  }, [createManualOrderState]);

  function adjustDate(date) {
    const dateObj = typeof date === "string" ? new Date(date) : date;

    // Extract the date parts
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // JavaScript months are 0-11
    const day = dateObj.getDate();

    // Pad the month and day with leading zeros if necessary
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;

    // Construct the date string in the 'yyyy-mm-ddTHH:mm:ss.sssZ' format
    const formattedDate = `${year}-${paddedMonth}-${paddedDay}T04:00:00.000Z`;
    return formattedDate;
  }

  const logCancelEvent = () =>
    logNewRelicMetricsEvent(REVISIT_CANCEL_EVENT, {
      order_number: orderDetails.customerDetail.custOrdId,
      index: orderIndex,
      text: revisitType,
      overlay_type: OVERLAY_TYPE_REVISIT_LEGACY,
    });

  return (
    <>
      <Row>
        <Form
          className="mo-form"
          form={form}
          layout={["vertical"]}
          onFinish={() => {
            createManualOrder({
              request: {
                deliveryNoteText: form.getFieldValue("notes"),
                scheduledDeliveryDate: adjustDate(selectDate.toDate()),
                manuallyAddedReasonCode: determineManuallyCode(revisitType),
                deliveryLines: collectSelectedLineItems().map((lineItem) => ({
                  deliveryLineNumber: lineItem.lineNbr,
                  modelNumber: lineItem.modelNbr,
                })),
                deliveryId: orderDetails.referenceId,
                hasExistingDelivery: true,
              },
            });
          }}
          onValuesChange={(v) => setFieldValue(v)}
        >
          {slide === 0 && (
            <>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="mo-reason-container">
                  <div className="manual-order-header">New manual order</div>
                  <div className="manual-order-selector">
                    <Form.Item name="revisitReason" label="Reason for revisit">
                      <div className="revisit-reason-wrapper">
                        <p className="revisit-reason">{revisitType}</p>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <CustomerInfo
                  showPen={false}
                  customerDetail={orderDetails.customerDetail}
                  workOrderNumber={
                    orderDetails.deliveryDetail &&
                    orderDetails.deliveryDetail.woDetail &&
                    orderDetails.deliveryDetail.woDetail.woNbr
                  }
                  orderNumber={
                    orderDetails.customerDetail &&
                    orderDetails.customerDetail.custOrdId
                  }
                  purchaseOrderDetailList={
                    orderDetails.deliveryDetail &&
                    orderDetails.deliveryDetail.poDetailList
                  }
                  location={
                    orderDetails.deliveryDetail &&
                    orderDetails.deliveryDetail.deliveryAgent &&
                    orderDetails.deliveryDetail.deliveryAgent.tmsId
                  }
                  orderSource={orderDetails.sourceSystem}
                />
              </Col>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="mo-line-items-container">
                  <ProductOrders
                    deliveryDetail={orderDetails.deliveryDetail}
                    setSelectedVendor={setSelectedVendor}
                    enabledVendor={enabledVendor}
                    setEnabledVendor={setEnabledVendor}
                  />
                </div>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="mo-notes-container">
                  <Form.Item
                    name="notes"
                    className="notes"
                    label="Add Note (required)"
                    labelCol={{ span: 24 }}
                  >
                    <TextArea
                      maxLength={255}
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      onChange={(e) => setAddNotes(e.target.value)}
                    />
                  </Form.Item>
                  <div className="note-footer">
                    <span className={"character-remaining-label"}>
                      {255 - addNotes.length} characters remaining
                    </span>
                  </div>
                </div>
              </Col>
              <Col
                xl={{ span: 4 }}
                lg={{ span: 8 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="mo-actions-container">
                  <Button
                    disabled={
                      (!isNewManualOrder() &&
                        (lineItems === undefined || lineItems.length === 0)) ||
                      notes === undefined ||
                      notes === ""
                    }
                    type="primary"
                    size="large"
                    onClick={() => {
                      setSlide(1);
                      setVendorDetails();
                      cleanDeliverySlots();
                      getDeliverySlots(orderDetails);
                      logNewRelicMetricsEvent(REVISIT_NEXT_EVENT, {
                        order_number: orderDetails.customerDetail.custOrdId,
                        index: orderIndex,
                        text: revisitType,
                        overlay_type: OVERLAY_TYPE_REVISIT_LEGACY,
                      });
                    }}
                  >
                    Select Date
                  </Button>
                  <div className="cancel-btn">
                    <Button
                      type="link"
                      size="large"
                      block
                      onClick={() => {
                        toggleManualOrder(false);
                        logCancelEvent();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          )}
          {slide === 1 && (
            <div className="manual-order-wrapper">
              <div className={getStatusHistory}>
                <div className="display-box-select-date">
                  <MOScheduleDate
                    revisitType={revisitType}
                    selectedVendor={selectedVendor}
                    orderDetails={orderDetails}
                    form={form}
                    setSlide={setSlide}
                    updateSelectDate={updateSelectDate}
                    updateSelectDateIndex={updateSelectDateIndex}
                    updateSelectedDateSlotsCount={updateSelectedDateSlotsCount}
                    deliverySlotsState={deliverySlotsState}
                    slotsByDate={slotsByDate}
                    cleanDeliverySlots={cleanDeliverySlots}
                    createManualOrderState={createManualOrderState}
                    cleanCreateManualOrder={cleanCreateManualOrder}
                    logCancelEvent={logCancelEvent}
                  />
                </div>
              </div>
            </div>
          )}
        </Form>
      </Row>
    </>
  );
};

export default ManualOrder;
