import React, { useContext } from "react";
import { Divider, Row } from "antd";
import "./OrderDetails.less";
import TimeWindow from "./TimeWindow/TimeWindow";
import DeliveryInfo from "./DeliveryInfo/DeliveryInfo";
import TrackerInfo from "./TrackerInfo/TrackerInfo";
import WorkOrder from "./WorkOrder/WorkOrder";
import ProductsDetails from "./ProductsDetails/ProductsDetails";
import Notes from "../../Notes/Notes";
import Pod from "../../Actions/ProofOfDelivery/Pod";
import PrintOrder from "../../Actions/PrintOrder/PrintOrder";
import { OrderDetailsReschedule } from "./OrderDetailsReschedule/OrderDetailsReschedule";
import permit from "../../../util/Permissions";
import { UserContext } from "../../../context/RootContext";
import DashboardContext from "../../../context/DashboardContext";
import RevisitDrawer from "./RevisitDrawer/RevisitDrawer";
import ShipToHomeRescheduleDrawer from "./SthRescheduleDrawer/ShipToHomeRescheduleDrawer";
import { checkActionAccessForOrderStatus } from "../ResultTable/helpers/helpers";
import { Revisit } from "./Revisit/Revisit";
import { ShipToHomeReschedule } from "./SthReschedule/ShipToHomeReschedule";
import * as constants from "../../../util/Constants";
import { DASHBOARD_STH_CONTEXT } from "../../../util/Constants";
import CustomerInfoCommons from "./CustomerInfo/CustomerInfoCommons";
import { RevisitCancel } from "./Revisit/RevisitCancel";

const OrderDetails = ({
  workOrderStatus,
  isWorkBin,
  orderDetails,
  trackerLink,
  loadDeliveryTracker,
  isCancelled,
  orderCardColor,
  hideCustomerInfoEdit,
  sthRefreshDeliveries,
  orderIndex,
}) => {
  const {
    currentDeliveryStatus,
    customerOrderNumber,
    reservationId,
    workOrderNumber,
    sellerOrganizationCode,
    orderType,
    source,
    customerDetail,
    deliveryDetail,
    purchaseOrderDetail,
    notes,
    isAppliance,
  } = orderDetails;
  const { isRevisit, revisitType } = deliveryDetail;

  const { user } = useContext(UserContext);
  const dashboardContext = useContext(DashboardContext);
  const workOrderStatusesThatAllowToCancelRevisit = [
    "Work Order Created",
    "Submitted And Approved",
    "Ready For Load",
    "Delivery Attempted",
    "Dispatch Rejected",
  ];

  const isApplianceWithRevisitEnabled =
    process.env.REACT_APP_REVISIT_ENABLED === "Y" && isAppliance;
  const isRevisitAllowed =
    permit("canCreateManualOrder", user) &&
    workOrderStatus !== "Work Order Cancelled" &&
    isApplianceWithRevisitEnabled;
  const isCancelRevisitAllowed =
    permit("cancelMO", user) &&
    isApplianceWithRevisitEnabled &&
    workOrderStatusesThatAllowToCancelRevisit.includes(workOrderStatus) &&
    isRevisit;
  const isReplacementRevisit = revisitType === constants.REVISIT_REPLACEMENT;

  const [isRevisitDrawerOpen, setRevisitDrawerOpen] = React.useState(false);
  const [isSthRescheduleDrawerOpen, setSthRescheduleDrawerOpen] =
    React.useState(false);

  function getActionInput(actionString) {
    switch (actionString) {
      case "pod":
        return dashboardContext === DASHBOARD_STH_CONTEXT
          ? {
              action: "sthPod",
              statusForAction: currentDeliveryStatus,
            }
          : {
              action: "pod",
              statusForAction: workOrderStatus,
            };
      case "reschedule":
        return {
          action: "reschedule",
          statusForAction: workOrderStatus,
        };
      default:
        return {
          action: "",
          statusForAction: "",
        };
    }
  }

  const deliveryDate = deliveryDetail.deliveryStartDateTime
    ? deliveryDetail.deliveryStartDateTime.split("T")[0]
    : "";

  function getDeliveryLineItemNumbers() {
    const lineNumbers = [];

    if (purchaseOrderDetail) {
      // Retrieve line numbers from every line item including parts and services
      purchaseOrderDetail.forEach((detail) => {
        if (detail.lineItems) {
          detail.lineItems.forEach((item) => {
            lineNumbers.push(item.lineNumber);
            if (item.partsAndServices) {
              item.partsAndServices.forEach((pasItem) => {
                lineNumbers.push(pasItem.lineNumber);
              });
            }
          });
        }
      });
    }
    // Retrieve line numbers from delivery line item
    if (deliveryDetail && deliveryDetail.deliveryLineItem) {
      lineNumbers.push(deliveryDetail.deliveryLineItem.lineNumber);
    }

    return lineNumbers;
  }

  function getLineItems() {
    return purchaseOrderDetail
      ? purchaseOrderDetail
          .flatMap((orderDetail) => orderDetail.lineItems || [])
          .map((lineItem) => ({
            orderLineReference: lineItem.lineNumber,
            quantityToCancel: parseInt(lineItem.quantity),
            skuCode: lineItem.skuNumber,
            skuCodeDesc: lineItem.itemDesc,
          }))
      : [];
  }

  function getPartsAndServices() {
    return purchaseOrderDetail
      ? purchaseOrderDetail
          .flatMap((orderDetail) => orderDetail.lineItems || [])
          .flatMap((lineItem) => lineItem.partsAndServices || [])
          .filter((partAndService) => partAndService)
          .map((partAndService) => ({
            orderLineReference: partAndService.lineNumber,
            quantityToCancel: parseInt(partAndService.quantity),
            skuCode: partAndService.skuNumber,
            skuCodeDesc: partAndService.itemDesc,
          }))
      : [];
  }

  function getCancelledLines() {
    return [].concat(getLineItems(), getPartsAndServices());
  }

  return (
    <div
      className="order-delivery-details"
      data-testid="order-delivery-details-container"
    >
      <div className={`order-status-history-section-${orderCardColor}`}>
        <Row className="outer-row">
          <div className="merged-container">
            <div className="time-window-container">
              {deliveryDetail.deliveryStartDateTime &&
              deliveryDetail.deliveryEndDateTime ? (
                <TimeWindow deliveryDetail={deliveryDetail} />
              ) : (
                "Delivery Window Not Set"
              )}
            </div>
            <Divider className="divider-timeline" type="vertical" />
            <div className="delivery-info-container">
              <DeliveryInfo
                orderType={orderType}
                deliveryDetail={deliveryDetail}
              />
            </div>
            <Divider className="divider-delivery-info" type="vertical" />
          </div>
          <div className="merged-content-divider-container">
            <Divider className="merged-content-divider" type="vertical" />
          </div>
          <div className="tracker-info-container">
            {loadDeliveryTracker && (
              <TrackerInfo
                orderIndex={orderIndex}
                customerOrderNumber={customerOrderNumber}
                trackerLink={trackerLink}
              />
            )}
          </div>
          {isRevisit && (
            <div className="revisit-pill-container">
              <div className="revisit-pill">
                <span>REVISIT</span>
                <span>| {revisitType}</span>
              </div>
            </div>
          )}
          <div className="horizontal-top-divider-container">
            <Divider className="horizontal-top-divider" type="horizontal" />
          </div>

          <div className="work-order-container">
            <WorkOrder
              isWorkBin={isWorkBin}
              orderIndex={orderIndex}
              customerOrderNumber={customerOrderNumber}
              workOrderNumber={workOrderNumber}
              purchaseOrderDetail={
                purchaseOrderDetail ? purchaseOrderDetail : null
              }
              deliveryDetail={deliveryDetail}
              storeNumber={sellerOrganizationCode}
              source={source}
              isAppliance={isAppliance}
            />
          </div>
          <div className="work-order-divider-container">
            <Divider className="work-order-divider" type="vertical" />
          </div>
          <div className="customer-info-container-dashboard-beta">
            <CustomerInfoCommons
              isWorkBin={isWorkBin}
              orderIndex={orderIndex}
              customerOrderNumber={customerOrderNumber}
              customerDetail={customerDetail}
              workOrderNumber={workOrderNumber}
              location={deliveryDetail.deliveryLocation}
              orderSource={"ORDERUP"}
              orderNumber={customerOrderNumber}
              hideCustomerInfoEdit={hideCustomerInfoEdit}
            />
          </div>
          <div className="customer-info-divider-container">
            <Divider className="customer-info-divider" type="vertical" />
          </div>
          <div className="product-detail-horizontal-top-divider-container">
            <Divider
              className="product-detail-horizontal-top-divider"
              type="horizontal"
            />
          </div>
          <div className="product-details-container">
            <ProductsDetails purchaseOrderDetail={purchaseOrderDetail} />
          </div>
          <div className="product-details-divider-container">
            <Divider className="product-details-divider" type="vertical" />
          </div>
          <div className="product-details-divider-horizontal-container">
            <Divider
              className="product-details-horizontal-divider"
              type="horizontal"
            />
          </div>
          <div className="notes-container-dashboard-beta">
            <Notes
              deliveryNoteStatus={"Success"}
              notes={notes}
              sortBy={"createdOn"}
              customerName={{
                firstName: customerDetail.firstName,
                lastName: customerDetail.lastName,
              }}
              deliveryDate={deliveryDate}
              orderNumber={customerOrderNumber}
              msNbr={null}
              defaultNoteLevel={constants.NOTES_LABELS.order}
              isComRevisit={isRevisit}
              referenceId={null}
              lineNumbers={getDeliveryLineItemNumbers()}
              view={constants.DASHBOARD_COMMON_CONTEXT}
              orderIndex={orderIndex}
            />
          </div>

          <div className="lower-horizontal-divider-container">
            <Divider className="lower-horizontal-divider" type="horizontal" />
          </div>
          <div className="actions-container">
            <div className="order-actions-wrapper">
              <div className="order-actions">
                {isRevisitAllowed && (
                  <Revisit
                    isRevisit={isRevisit}
                    isReplacementRevisit={isReplacementRevisit}
                    setDrawerOpen={setRevisitDrawerOpen}
                  />
                )}
                {dashboardContext !== constants.DASHBOARD_STH_CONTEXT &&
                checkActionAccessForOrderStatus(
                  getActionInput("reschedule")
                ) ? (
                  <OrderDetailsReschedule
                    class="rescheduleButton"
                    isWorkBin={isWorkBin}
                    workOrderNumber={workOrderNumber}
                    orderIndex={orderIndex}
                    customerOrderNumber={customerOrderNumber}
                    location={deliveryDetail.deliveryLocation}
                    orderSource={"ORDERUP"}
                  />
                ) : (
                  <></>
                )}
                {dashboardContext === constants.DASHBOARD_STH_CONTEXT &&
                !isCancelled &&
                orderDetails.isReschedulable ? (
                  <ShipToHomeReschedule
                    orderIndex={orderIndex}
                    customerOrderNumber={customerOrderNumber}
                    setDrawerOpen={setSthRescheduleDrawerOpen}
                  />
                ) : (
                  <></>
                )}
                {checkActionAccessForOrderStatus(getActionInput("pod")) ? (
                  <Pod
                    referenceId={reservationId}
                    reservationId={reservationId}
                    customerDetail={{
                      custFirstName: customerDetail.firstName,
                      custLastName: customerDetail.lastName,
                      scheduledDeliveryDate: deliveryDate,
                    }}
                    sourceSystem={source}
                    orderNumber={customerOrderNumber}
                    orderIndex={orderIndex}
                  />
                ) : (
                  <></>
                )}
                {isCancelRevisitAllowed && (
                  <RevisitCancel
                    workOrderNumber={workOrderNumber}
                    customerOrderNumber={customerOrderNumber}
                    orderIndex={orderIndex}
                    deliveryLocation={deliveryDetail.deliveryLocation}
                    cancelledLines={getCancelledLines()}
                  />
                )}
                <PrintOrder
                  newDesign={true}
                  customerDetail={customerDetail}
                  purchaseOrderDetailList={purchaseOrderDetail}
                  customerOrderNumber={customerOrderNumber}
                  deliveryDate={deliveryDate}
                  notes={notes}
                  reservationId={reservationId}
                  sourceSystem={source}
                  orderNumber={customerOrderNumber}
                  orderIndex={orderIndex}
                />
              </div>
            </div>
          </div>
        </Row>
        {process.env.REACT_APP_REVISIT_ENABLED === "Y" && (
          <RevisitDrawer
            customerOrderNumber={customerOrderNumber}
            workOrderNumber={workOrderNumber}
            customerDetail={customerDetail}
            purchaseOrderDetail={purchaseOrderDetail}
            location={deliveryDetail.deliveryLocation}
            orderCardColor={orderCardColor}
            isDrawerOpen={isRevisitDrawerOpen}
            setDrawerOpen={setRevisitDrawerOpen}
            orderIndex={orderIndex}
          />
        )}
        {
          <ShipToHomeRescheduleDrawer
            deliveryDetail={deliveryDetail}
            reservationId={reservationId}
            isDrawerOpen={isSthRescheduleDrawerOpen}
            setDrawerOpen={setSthRescheduleDrawerOpen}
            sthRefreshDeliveries={sthRefreshDeliveries}
            customerOrderNumber={customerOrderNumber}
            orderIndex={orderIndex}
          />
        }
      </div>
    </div>
  );
};

export default OrderDetails;
