import React, { useContext } from "react";
import axios from "axios";
import OrderContext from "../../../context/OrderContext";
import Confirmation from "../../Confirmation/Confirmation";
import { message } from "antd";
import getBffUrl from "../../../util/getBffUrl";

const Cancel = (props) => {
  const { referenceId, orderNumber, orderIndex, overlayType } = props;
  const { updateOrder } = useContext(OrderContext);

  function orderCancellation() {
    cancelOrder(referenceId);
  }

  function cancelOrder(referenceId) {
    axios
      .delete(
        `${getBffUrl()}/deliveryOrder/v1/cancelManualOrder/${referenceId}`
      )
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data === "Success"
        ) {
          message.success("This order has been cancelled");
          updateOrder();
        } else {
          message.error(
            "Something went wrong and this order has not been cancelled."
          );
        }
      })
      .catch((error) => {
        message.error(
          "Something went wrong and this order has not been cancelled."
        );
        console.log("Error calling cancelManualOrder", error);
      });
  }

  return (
    <div>
      <div id="cancel-revisit-button">
        <Confirmation
          referenceId={referenceId}
          orderCancellation={orderCancellation}
          orderNumber={orderNumber}
          orderIndex={orderIndex}
          overlayType={overlayType}
        />
      </div>
    </div>
  );
};
export default Cancel;
