import React from "react";
import ReactDOM from "react-dom";
import PFLogic from "./app/components/PFLogic";
import { PFProvider, UserProvider } from "./app/context/RootContext";
import App from "./app/App";

ReactDOM.render(
  <PFProvider>
    <PFLogic>
      <UserProvider>
        <App />
      </UserProvider>
    </PFLogic>
  </PFProvider>,
  document.getElementById("app")
);
