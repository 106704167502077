import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import MicroUI from "../../DeliveryMicroUI/MicroUI";
import OrderContext from "../../../context/OrderContext";
import LegacyReschedule from "./LegacyReschedule/LegacyReschedule.js";
import axios from "axios";
import permit from "../../../util/Permissions";
import { UserContext } from "../../../context/RootContext";
import moment from "moment";
import getBffUrl from "../../../util/getBffUrl";
import useNewRelicMetrics from "../../../hooks/newRelicMetricsHook.js";
import {
  CANCEL_RESCHEDULE_MODAL_EVENT,
  CLOSE_RESCHEDULE_MODAL_EVENT,
  RESCHEDULE_EVENT,
} from "../../../util/NewRelicConstants.js";

const Reschedule = (props) => {
  const {
    workOrderDetail,
    originalMSN,
    purchaseOrderDetailList,
    customerDetail,
    location,
    orderSource,
    orderIndex,
    orderNumber,
  } = props;
  const { user } = useContext(UserContext);
  const [slotsCount, setSlotsCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const { updateOrder } = useContext(OrderContext);
  const enabledDates = [];
  const emptyDates = [];
  const [response, setResponse] = useState(null);
  const [dates, setDates] = useState({
    enabled: [],
    empty: [],
  });
  const { logNewRelicMetricsEvent } = useNewRelicMetrics();

  function getManufacturingVendors() {
    const vendors = [];
    purchaseOrderDetailList.forEach((poDetail, i) => {
      vendors.push(poDetail.vendorNbr);
    });
    return vendors.toString();
  }

  function passSelectedDate(selectedDate) {
    response.data.deliverySlots.forEach((slot, i) => {
      if (
        moment(slot.mdate).utc().format("YYYY-MM-DD") ===
        moment(selectedDate).format("YYYY-MM-DD")
      ) {
        setSlotsCount(slot.mavails);
      }
    });
  }

  function getEndDate(date) {
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 92);
    return endDate;
  }

  const getDeliveryDates = async () => {
    const today = new Date();
    const endDate = getEndDate(today);
    if (workOrderDetail != null && getManufacturingVendors() !== "") {
      await axios
        .get(`${getBffUrl()}/dashboard/v1/deliverySlots`, {
          params: {
            zipCode: customerDetail.custZipCode,
            vendorNbr: getManufacturingVendors(),
            startDate: moment(today).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            checkCutOffTime:
              orderSource === "ADMS"
                ? process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_MO
                : process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_REGULAR,
            deliveryVendorNbr: workOrderDetail.vendorNbr,
          },
        })
        .then((response) => {
          if (response && response.status === 200) {
            setResponse(response);
            if (
              response.data &&
              response.data.errors === null &&
              response.data.deliverySlots
            ) {
              response.data.deliverySlots.forEach((slot, i) => {
                if (
                  moment(slot.mdate).utc().format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ) {
                  setSlotsCount(slot.mavails);
                }

                if (permit("applyRescheduleNoOverrideFilter", user)) {
                  if (slot.mavails) {
                    enabledDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                } else {
                  enabledDates.push(
                    moment(slot.mdate).utc().format("YYYY-MM-DD")
                  );
                  if (!slot.mavails) {
                    emptyDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                }
              });
              setDates({
                enabled: enabledDates,
                empty: emptyDates,
              });
            }
          } else {
            console.log("Error accessing the Delivery slots");
          }
        })
        .catch(() => {
          console.log("Error calling Delivery slots");
        });
    } else if (getManufacturingVendors() === "" && workOrderDetail) {
      await axios
        .get(`${getBffUrl()}/dashboard/v1/deliverySlots`, {
          params: {
            zipCode: customerDetail.custZipCode,
            vendorNbr: workOrderDetail.vendorNbr,
            startDate: moment(today).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            checkCutOffTime:
              orderSource === "ADMS"
                ? process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_MO
                : process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_REGULAR,
            deliveryVendorNbr: workOrderDetail.vendorNbr,
          },
        })
        .then((response) => {
          if (response && response.status === 200) {
            setResponse(response);
            if (
              response.data &&
              response.data.errors === null &&
              response.data.deliverySlots
            ) {
              response.data.deliverySlots.forEach((slot, i) => {
                if (
                  moment(slot.mdate).utc().format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ) {
                  setSlotsCount(slot.mavails);
                }
                if (permit("applyRescheduleNoOverrideFilter", user)) {
                  if (slot.mavails) {
                    enabledDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                } else {
                  enabledDates.push(
                    moment(slot.mdate).utc().format("YYYY-MM-DD")
                  );
                  if (!slot.mavails) {
                    emptyDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                }
              });
              setDates({
                enabled: enabledDates,
                empty: emptyDates,
              });
            }
          } else {
            console.log("Error accessing the Delivery slots");
          }
        })
        .catch(() => {
          console.log("Error calling Delivery slots");
        });
    } else {
      await axios
        .get(`${getBffUrl()}/dashboard/v1/deliverySlots`, {
          params: {
            zipCode: customerDetail.custZipCode,
            vendorNbr: getManufacturingVendors(),
            startDate: moment(today).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
            checkCutOffTime:
              orderSource === "ADMS"
                ? process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_MO
                : process.env
                    .REACT_APP_DELIVERYSLOTS_CHECKCUTOFFTIME_RESCHEDULE_REGULAR,
          },
        })
        .then((response) => {
          if (response && response.status === 200) {
            setResponse(response);
            if (
              response.data &&
              response.data.errors === null &&
              response.data.deliverySlots
            ) {
              response.data.deliverySlots.forEach((slot, i) => {
                if (
                  moment(slot.mdate).utc().format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ) {
                  setSlotsCount(slot.mavails);
                }

                if (permit("applyRescheduleNoOverrideFilter", user)) {
                  if (slot.mavails) {
                    enabledDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                } else {
                  enabledDates.push(
                    moment(slot.mdate).utc().format("YYYY-MM-DD")
                  );
                  if (!slot.mavails) {
                    emptyDates.push(
                      moment(slot.mdate).utc().format("YYYY-MM-DD")
                    );
                  }
                }
              });
              setDates({
                enabled: enabledDates,
                empty: emptyDates,
              });
            }
          } else {
            console.log("Error accessing the Delivery slots");
          }
        })
        .catch(() => {
          console.log("Error calling Delivery slots");
        });
    }
  };

  return (
    <div>
      {(orderSource.trim().toUpperCase().includes("AOS") ||
        orderSource.trim().toUpperCase().includes("ONLINE") ||
        orderSource.trim().toUpperCase().includes("ADMS") ||
        orderSource.trim().toUpperCase().includes("ORDERUP")) &&
        permit("reschedule", user) &&
        (!permit("applyRescheduleProductFilter", user) ||
          (permit("applyRescheduleProductFilter", user) &&
            getManufacturingVendors().includes(user.mvendorNum))) && (
          <div id="reschedule-button">
            <Button
              className="default-btn"
              block
              onClick={() => {
                logNewRelicMetricsEvent(RESCHEDULE_EVENT, {
                  order_number: orderNumber,
                  text: "button",
                  index: orderIndex,
                });
                setModalVisible(true);
                getDeliveryDates();
              }}
            >
              Reschedule
            </Button>
          </div>
        )}
      <Modal
        open={modalVisible}
        title={null}
        footer={null}
        centered
        width={
          orderSource.trim().toUpperCase().includes("ORDERUP") ? "87.5%" : "80%"
        }
        destroyOnClose
        onCancel={() => {
          // The difference between OU and non-OU flows is that
          // the reschedule call in OU is out of our control, it happens in the app that runs in iframe.
          // That is why in non-OU flow the order will be updated after reschedule call
          // returns 200 response status.
          // But in OU-flow here we update order when modal is closed by user after he finished his work in OU
          if (orderSource.trim().toUpperCase().includes("ORDERUP")) {
            logNewRelicMetricsEvent(CLOSE_RESCHEDULE_MODAL_EVENT, {
              order_number: orderNumber,
              index: orderIndex,
              overlay_type: "commons",
            });
            updateOrder();
          } else {
            logNewRelicMetricsEvent(CLOSE_RESCHEDULE_MODAL_EVENT, {
              order_number: orderNumber,
              index: orderIndex,
              overlay_type: "legacy",
            });
          }
          setModalVisible(false);
          //document.body.style.overflow = "auto";
        }}
      >
        {orderSource.trim().toUpperCase().includes("ORDERUP") ? (
          <MicroUI
            location={location}
            workOrderNumber={workOrderDetail && workOrderDetail.woNbr}
            orderNumber={customerDetail.orderNumber}
          />
        ) : (
          <LegacyReschedule
            customerDetail={customerDetail}
            originalMSN={originalMSN}
            dates={dates}
            orderNumber={orderNumber}
            slotsCount={slotsCount}
            getDeliveryDates={getDeliveryDates}
            passSelectedDate={passSelectedDate}
            handleClose={() => {
              setModalVisible(false);
              updateOrder();
            }}
            simpleClose={() => {
              logNewRelicMetricsEvent(CANCEL_RESCHEDULE_MODAL_EVENT, {
                order_number: orderNumber,
                index: orderIndex,
                overlay_type: "legacy",
              });
              setModalVisible(false);
            }}
          ></LegacyReschedule>
        )}
      </Modal>
    </div>
  );
};

Reschedule.propTypes = {
  workOrderDetail: PropTypes.any,
  purchaseOrderDetailList: PropTypes.any,
  workOrderNumber: PropTypes.string,
  customerDetail: PropTypes.any,
  deliveryDates: PropTypes.any,
  location: PropTypes.string,
  orderSource: PropTypes.string,
};

export default Reschedule;
