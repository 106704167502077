import { useContext, useEffect, useState } from "react";
import { useUrlData } from "./useUrlData";
import axios from "axios";
import LocationContext from "../../context/LocationContext";
import {
  getArchiveUrl,
  getRouteSummaryUrl,
  getUnassignedStopsUrl,
} from "../../util/RoutePlanner/routePlannerUtils";
import moment from "moment";
import { formatTo12Hour } from "../../util/DateFormatter";
import useNotification from "../../hooks/useNotification";

/**
 * A React hook for fetching and managing route planner data.
 *
 * @module useRoutePlannerData
 * @param {boolean} allowDatePicker - A flag to determine if the date picker functionality is enabled.
 * @param {String} tab - Current selected tab
 * @returns {Object} An object containing the route data and a setter function for the data.
 *
 * @example
 * const { data } = useRoutePlannerData(true, "planning");
 */
const useRoutePlannerData = (allowDatePicker, tab) => {
  const [data, setData] = useState([]);
  const { getQueryParam, getAllQueryParams } = useUrlData();
  const dateDefaultValue = getQueryParam("date");
  const [selectedDate, setSelectedDate] = useState(dateDefaultValue || null);
  const { currentLocation } = useContext(LocationContext);
  let searchInput = "";
  let searchParam = "";

  const allParams = getAllQueryParams();
  if (allParams !== "") {
    if (allParams.includes("customerOrderNumber")) {
      searchInput = getQueryParam("customerOrderNumber");
      searchParam = "customerOrderNumber";
    } else if (allParams.includes("customerLastName")) {
      searchInput = getQueryParam("customerLastName");
      searchParam = "customerLastName";
    } else if (allParams.includes("msNumber")) {
      searchInput = getQueryParam("msNumber");
      searchParam = "msNumber";
    }
  }
  const [searchTerm, setSearchTerm] = useState(searchInput || null);
  const [enableDownloadManifest, setEnableDownloadManifest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    const shouldFetchData =
      tab === "dispatch" ||
      ((tab === "planning" || tab === "unassigned") && selectedDate) ||
      (tab === "archive" && searchTerm);

    if (shouldFetchData) {
      fetchData();
    }

    if (tab === "dispatch" || (tab === "planning" && selectedDate)) {
      setEnableDownloadManifest(true);
    }
  }, [selectedDate, searchTerm]);

  /**
   * Fetches route summary data based on the selected date, tab, and location.
   * If no selected date is provided, sets the data based on the tab and allowDatePicker values.
   *
   * @async
   * @function fetchData
   * @returns {Promise<void>} A promise that resolves when the data is fetched and set.
   * @throws {Error} If an error occurs while fetching the data.
   *
   * @example
   * useEffect(() => {
   *   fetchData();
   * }, [selectedDate, allowDatePicker]);
   */
  async function fetchData() {
    let routeDomainURL;
    let drdsApiResponse;
    let routeData;

    try {
      setLoading(true);

      if (tab === "planning" || tab === "unassigned") {
        if (selectedDate && allowDatePicker) {
          routeDomainURL =
            tab === "planning"
              ? getRouteSummaryUrl(
                  tab,
                  currentLocation.locationNumber,
                  selectedDate
                )
              : getUnassignedStopsUrl(
                  currentLocation.locationNumber,
                  selectedDate
                );

          drdsApiResponse = await axios.get(routeDomainURL);
          routeData =
            tab === "planning"
              ? parsePlanningData(drdsApiResponse.data)
              : parseUnassignedData(drdsApiResponse.data);
        } else {
          setData([]);
          return;
        }
      } else if (tab === "dispatch") {
        routeDomainURL = getRouteSummaryUrl(
          tab,
          currentLocation.locationNumber,
          moment().format("YYYY-MM-DD")
        );
        drdsApiResponse = await axios.get(routeDomainURL);
        routeData = parseDispatchSummaryData(drdsApiResponse.data);
      } else if (tab === "archive") {
        if (searchTerm) {
          routeDomainURL = getArchiveUrl(
            currentLocation.locationNumber,
            searchParam,
            searchTerm
          );
          drdsApiResponse = await axios.get(routeDomainURL);
          routeData = parseArchiveData(drdsApiResponse.data);
        } else {
          setData([]);
          return;
        }
      }

      if (routeData) {
        setData(routeData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      setData([]);
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  }

  function parsePlanningData(drdsApiResponse) {
    const dataSource = [];
    if (drdsApiResponse != null) {
      drdsApiResponse.forEach((dispatchSummary, idx) => {
        dataSource.push({
          key: idx,
          carrierName: dispatchSummary.carrierName,
          driverName: dispatchSummary.driverName,
          driverId: dispatchSummary.driverId,
          lastUpdatedTimestamp: dispatchSummary.lastUpdatedTimestamp,
          numberOfStops: dispatchSummary.numberOfStops,
          routeId: dispatchSummary.routeId,
          routeStatus: dispatchSummary.routeStatus,
          truckId: dispatchSummary.truckId,
        });
      });
    }
    return dataSource;
  }

  function parseUnassignedData(drdsApiResponse) {
    const dataSource = [];
    if (drdsApiResponse != null) {
      drdsApiResponse.forEach((unassignedStop, idx) => {
        dataSource.push({
          key: idx,
          orderNumber: unassignedStop.orderNumber,
          workOrderNumber: unassignedStop.workOrderNumber,
          msNumber: unassignedStop.msNumber,
          customerName: unassignedStop.customerName,
          address: unassignedStop.address,
          serviceType: unassignedStop.serviceType,
          deliveryWindow: unassignedStop.deliveryWindow,
        });
      });
    }
    return dataSource;
  }

  function parseArchiveData(archiveResponse) {
    const dataSource = [];
    let inc = 0;
    if (archiveResponse != null) {
      archiveResponse.forEach((archiveArray) => {
        archiveArray.stops.forEach((allStops, idx) => {
          dataSource.push({
            key: `${inc}${idx}`,
            routeId: archiveArray.routeId,
            driverName: archiveArray.driverName,
            orderNumber: allStops.orderNumber,
            workOrderNumber: allStops.workOrderNumber,
            msn: allStops.msNumber,
            customerName: allStops.customerName,
            serviceType: allStops.serviceType,
            deliveryDate:
              allStops.actualArrivalDateTime &&
              allStops.actualArrivalDateTime.split(" ")[0],
            actualArrivalDateTime:
              allStops.actualArrivalDateTime &&
              getArrivalTime(allStops.actualArrivalDateTime),
            timeAtStop: allStops.timeAtStop,
            deliveryStatus: allStops.deliveryStatus,
            stopId: allStops.stopId,
          });
        });
        inc++;
      });
    }
    return dataSource;
  }

  function parseDispatchSummaryData(drdsApiResponse) {
    const dataSource = [];
    if (drdsApiResponse != null) {
      drdsApiResponse.forEach((dispatchSummary, idx) => {
        dataSource.push({
          key: idx,
          truckId: dispatchSummary.truckId,
          routeId: dispatchSummary.routeId,
          driverName: dispatchSummary.driverName,
          driverId: dispatchSummary.driverId,
          carrierName: dispatchSummary.carrierName,
          numberOfStops: dispatchSummary.numberOfStops,
          completionEta: dispatchSummary.completionEta,
        });
      });
    }
    return dataSource;
  }

  function getArrivalTime(estArrivalDateTime) {
    return formatTo12Hour(estArrivalDateTime.split(" ")[1]);
  }

  const getSelectedDateOrDefault = () => (selectedDate ? selectedDate : null);

  return {
    loading,
    error,
    data,
    getSelectedDateOrDefault,
    setSelectedDate,
    setSearchTerm,
    contextHolder,
    enableDownloadManifest,
  };
};

export default useRoutePlannerData;
