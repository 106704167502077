import { useState, useEffect, useContext } from "react";
import { getRouteDetailsUrl } from "../../util/RoutePlanner/routePlannerUtils";
import { useParams } from "react-router-dom";
import axios from "axios";
import LocationContext from "../../context/LocationContext";
import useNotification from "../../hooks/useNotification";

/**
 * Custom hook to fetch route details data.
 *
 * @param {string} tab - The current tab selected.
 * @returns {{stopsData: Array, driverData: Object}} - The fetched data.
 */
export const useRouteDetailsData = (tab) => {
  const [stopsData, setStopsData] = useState([]);
  const [driverData, setDriverData] = useState({});
  const { routeId } = useParams();
  const [loading, setLoading] = useState(true);
  const { currentLocation } = useContext(LocationContext);
  const [error, setError] = useState(null);
  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // setError(null);
      try {
        const response = await axios.get(
          getRouteDetailsUrl(tab, currentLocation.locationNumber, routeId)
        );
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const [data] = response.data;
        const { stops, ...driverInfo } = data;
        setStopsData(stops);
        setDriverData(driverInfo);
      } catch (error) {
        console.error("Error fetching route details:", error);
        setError(error.message);
        openErrorNotification({
          message: "Error Retrieving Data",
          description:
            "We're Sorry! There is no data available at this time. Please try again later.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [routeId]);

  return { loading, error, stopsData, driverData, contextHolder };
};
