import React, { useState, useRef } from "react";
import "./Dashboard.less";
import Deliveries from "../Deliveries/Deliveries";
import SearchContext from "../../context/SearchContext";

const Dashboard = () => {
  const filters = useRef({});

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const value = {
    setValues,
    values,
    filters,
    loading,
    setLoading,
  };
  return (
    <div className="dashboard-wrapper">
      <div id="dashboard-container">
        <SearchContext.Provider value={value}>
          <Deliveries />
        </SearchContext.Provider>
      </div>
    </div>
  );
};

export default Dashboard;
