import React, { createContext, useReducer } from "react";

import userDetailsReducer from "./reducers/userDetailsReducer";
import userInitialState from "./initialStates/userDetailsInitialState";
import { changeRole, getUserDetails } from "./actions/userDetailsActions";

import mailReducer from "./reducers/mailReducer";
import mailInitialState from "./initialStates/mailInitialState";
import { deleteMail, getMail, getHistoryMail } from "./actions/mailActions";

import mailMessageReducer from "./reducers/mailMessageReducer";
import mailMessageInitialState from "./initialStates/mailMessageInitialState";
import { cleanMailMessage, getMailMessage } from "./actions/mailMessageActions";

import createMessageReducer from "./reducers/createMailReducer";
import createMessageInitialState from "./initialStates/createMessageInitialState";
import { createMessage, cleanCreateMessage } from "./actions/createMailActions";
import closeMailMessageReducer from "./reducers/closeMailMessageReducer";
import {
  cleanCloseMailMessage,
  closeMailMessage,
} from "./actions/closeMailMessageActions";
import closeMailMessageInitialState from "./initialStates/closeMailMessageInitialState";
import pfReducer from "./reducers/pfReducer";
import pfInitialState from "./initialStates/pfInitialState";
import { identityMeCall } from "./actions/pfActions";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(
    userDetailsReducer,
    userInitialState
  );

  return (
    <UserContext.Provider
      value={{
        user: userState.user,
        loading: userState.loading,
        error: userState.error,
        getUserDetails: getUserDetails(userDispatch),
        changeRole: changeRole(userDispatch),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// export const LocationContext = createContext({});

// export const LocationProvider = ({ children }) => {

//     const [ currentLocation, locationDispatch ] = useReducer( locationReducer, locationInitialState );

//     return (
//         <LocationContext.Provider
//             value={{
//                 currentLocation,
//                 setCurrentLocation: setLocation(locationDispatch),
//             }}
//         >
//             {children}
//         </LocationContext.Provider>
//     );
// };

// export const SearchContext = createContext({});

// export const SearchProvider = ({ children }) => {

//     const [ searchSelection, searchDispatch ] = useReducer( searchReducer, searchInitialState );

//     return (
//         <SearchContext.Provider
//             value={{
//                 searchSelection,
//                 setSearchSelection: setSearch(searchDispatch),
//             }}
//         >
//             {children}
//         </SearchContext.Provider>
//     );
// };

// export const DeliveriesContext = createContext({});

// export const DeliveriesProvider = ({ children }) => {

//     const [ deliveriesState, deliveriesDispatch ] = useReducer( deliveriesReducer, deliveriesInitialState);

//     return (
//         <DeliveriesContext.Provider
//             value={{
//                 deliveriesState,
//                 getDeliveries: getDeliveries(deliveriesDispatch)
//             }}
//         >
//             {children}
//         </DeliveriesContext.Provider>
//     );
// };

export const MailContext = createContext({});

export const MailProvider = ({ children }) => {
  const [mailState, mailDispatch] = useReducer(mailReducer, mailInitialState);

  return (
    <MailContext.Provider
      value={{
        mailState,
        getMail: getMail(mailDispatch),
        deleteMail: deleteMail(mailDispatch),
        getHistoryMail: getHistoryMail(mailDispatch),
      }}
    >
      {children}
    </MailContext.Provider>
  );
};

export const MailMessageContext = createContext({});

export const MailMessageProvider = ({ children }) => {
  const [mailMessageState, mailMessageDispatch] = useReducer(
    mailMessageReducer,
    mailMessageInitialState
  );

  return (
    <MailMessageContext.Provider
      value={{
        mailMessageState,
        getMailMessage: getMailMessage(mailMessageDispatch),
        cleanMailMessage: cleanMailMessage(mailMessageDispatch),
      }}
    >
      {children}
    </MailMessageContext.Provider>
  );
};

export const CreateMessageContext = createContext({});

export const CreateMessageProvider = ({ children }) => {
  const [createMessageState, createMessageDispatch] = useReducer(
    createMessageReducer,
    createMessageInitialState
  );

  return (
    <CreateMessageContext.Provider
      value={{
        createMessageState,
        createMessage: createMessage(createMessageDispatch),
        cleanCreateMessage: cleanCreateMessage(createMessageDispatch),
      }}
    >
      {children}
    </CreateMessageContext.Provider>
  );
};

export const CloseMailMessageContext = createContext({});

export const CloseMailMessageProvider = ({ children }) => {
  const [closeMailMessageState, closeMailMessageDispatch] = useReducer(
    closeMailMessageReducer,
    closeMailMessageInitialState
  );
  return (
    <CloseMailMessageContext.Provider
      value={{
        closeMailMessageState,
        closeMailMessage: closeMailMessage(closeMailMessageDispatch),
        cleanCloseMailMessage: cleanCloseMailMessage(closeMailMessageDispatch),
      }}
    >
      {children}
    </CloseMailMessageContext.Provider>
  );
};

export const PFContext = createContext({});

export const PFProvider = ({ children }) => {
  const [pfState, pfDispatch] = useReducer(pfReducer, pfInitialState);

  return (
    <PFContext.Provider
      value={{
        data: pfState.data,
        loading: pfState.loading,
        error: pfState.error,
        identityMeCall: identityMeCall(pfDispatch),
      }}
    >
      {children}
    </PFContext.Provider>
  );
};
