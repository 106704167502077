import React, { useState, useMemo } from "react";
import "./SearchBarComponent.less";
import { Input, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useMatch, useResolvedPath } from "react-router-dom";
import { getOptions } from "./SearchBarOptions";
import { useUrlData } from "../../../hooks/RoutePlanner/useUrlData";

/**
 * NOTE: This component is currently a work in progress and not yet adopted in the codebase outside of route-planner view
 *
 * This component automatically populates the available options by checking the route
 * The default option is automatically selected by checking for the "default" entry
 */
export default function SearchBarComponent({ configOverride, setSearchTerm }) {
  // Config
  const match = useMatch("*");
  const resolvedPath = useResolvedPath(match.pathname);
  const availableOptions = getOptions(configOverride, resolvedPath);

  // States
  const [selectedOption, setSelectedOption] = useState(
    availableOptions.find((option) => option.value === "default")
  );
  const [userInput, setUserInput] = useState("");
  const { getAllQueryParams, setQueryParam, deleteQueryParam } = useUrlData();
  // Memo
  const disableSearchButton = useMemo(() => {
    return !isInputValid(userInput, selectedOption.validationString);
  }, [userInput, selectedOption]);

  function setUpSearchData(input) {
    const allParams = getAllQueryParams();
    if (allParams != null) {
      if (allParams.includes("customerOrderNumber")) {
        deleteQueryParam("customerOrderNumber");
      } else if (allParams.includes("customerLastName")) {
        deleteQueryParam("customerLastName");
      } else if (allParams.includes("msNumber")) {
        deleteQueryParam("msNumber");
      }
    }
    if (selectedOption.value === "orderNumber") {
      setQueryParam("customerOrderNumber", input);
    } else if (selectedOption.value === "customerLastName") {
      setQueryParam("customerLastName", input);
    } else {
      setQueryParam("msNumber", input);
    }
    setSearchTerm(input);
  }

  return (
    <div className="search-bar-container">
      <Select
        className="search-bar-select"
        showArrow={false}
        defaultValue={selectedOption.displayName}
        onChange={(selection) => {
          setSelectedOption(
            availableOptions.find((option) => option.value === selection)
          );
        }}
      >
        {availableOptions
          .filter((option) => option.value !== "default")
          .map((option, index) => (
            <Select.Option key={index} value={option.value} role="option">
              {option.displayName}
            </Select.Option>
          ))}
      </Select>
      <Input
        className="search-bar-input"
        placeholder={selectedOption.placeholder}
        onPressEnter={() => {
          setUpSearchData(userInput);
        }}
        addonAfter={
          <Button
            className="search-button"
            type="text" //This applies a transparent background
            icon={<SearchOutlined />}
            disabled={disableSearchButton}
            onClick={() => {
              setUpSearchData(userInput);
            }}
          />
        }
        onChange={(event) => {
          setUserInput(event.target.value);
        }}
      />
    </div>
  );
}

function isInputValid(input, validationString) {
  return new RegExp(validationString, "i").test(input);
}
