import { useContext, useEffect, useState } from "react";
import axios from "axios";
import LocationContext from "../../context/LocationContext";
import {
  getRouteManifestUrl,
  getRouteManifestDetailsUrl,
} from "../../util/RoutePlanner/routePlannerUtils";
import { getTodayDate } from "../../util/DateFormatter";
import useNotification from "../../hooks/useNotification";

const useRoutePlannerManifestData = (
  tab,
  routeId,
  selectedDate,
  isModalVisible
) => {
  const [data, setData] = useState([]);
  const { currentLocation } = useContext(LocationContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    if (isModalVisible && tab !== null) {
      fetchData();
    }
  }, [isModalVisible]);

  async function fetchData() {
    let routeDomainManifestURL;
    let drdsApiResponse;
    setLoading(true);
    try {
      if (tab === "planning" || tab === "dispatch") {
        if (selectedDate && routeId) {
          routeDomainManifestURL = getRouteManifestDetailsUrl(
            currentLocation.locationNumber,
            tab,
            selectedDate,
            routeId
          );
          drdsApiResponse = await axios.get(routeDomainManifestURL);
        } else {
          if (tab === "dispatch") {
            selectedDate = getTodayDate();
          }
          routeDomainManifestURL = getRouteManifestUrl(
            currentLocation.locationNumber,
            tab,
            selectedDate
          );
          drdsApiResponse = await axios.get(routeDomainManifestURL);
        }
      }
      if (drdsApiResponse) {
        setData(drdsApiResponse.data);
      } else {
        setData([]);
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      setData([]);
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    error,
    loading,
    contextHolder,
  };
};

export default useRoutePlannerManifestData;
