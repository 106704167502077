import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import "./ManifestButton.less";
import PropTypes from "prop-types";
import useRoutePlannerManifestData from "../../../hooks/RoutePlanner/useRoutePlannerManifestData";

const ManifestModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedDate,
  routeId,
  tab,
  isEnabledCSV,
  modalTitle,
}) => {
  const { data, error, loading, contextHolder } = useRoutePlannerManifestData(
    tab,
    routeId,
    selectedDate,
    isModalVisible
  );
  const disableButtonFlag = data.length === 0 ? true : false;
  return (
    <Modal
      style={{ top: 350, width: 520 }}
      title={<div className="manifest-modal-header-label">{modalTitle}</div>}
      footer={[
        <Button
          key="cancel"
          aria-label="download-manifest-cancel"
          type="secondary"
          onClick={() => {
            setIsModalVisible(false);
          }}
        >
          Cancel
        </Button>,
      ]}
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      {loading && (
        <div className="rp-details-spinner" data-testid="rp-details-spinner">
          <Spin size="large" />
        </div>
      )}
      <div>
        {contextHolder}
        <div className="manifest-center-container">
          <div className="manifest-pdf-container">
            <span className="pdf-container-text">
              This version can be printed for the Drivers to take on their
              routes.
            </span>
          </div>
          <div className="pdf-container-button">
            <Button
              key="printable-manifest"
              aria-label="printable-manifest"
              type="primary"
              onClick={() => {
                setIsModalVisible(false);
              }}
              disabled={disableButtonFlag}
            >
              Printable Manifest
            </Button>
          </div>
          {isEnabledCSV && (
            <>
              <div className="manifest-csv-container">
                <span className="csv-container-text">
                  This version can be used as input for the Revisit and Parts
                  tool; copy the data to your clipboard and follow the normal
                  process.
                </span>
              </div>
              <div className="csv-container-button">
                <Button
                  key="copy-to-clipboard"
                  aria-label="copy-to-clipboard"
                  type="primary"
                  onClick={() => {
                    setIsModalVisible(false);
                  }}
                  disabled={disableButtonFlag}
                >
                  Copy to clipboard
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const ManifestButton = (props) => {
  const {
    routeId,
    selectedDate,
    isEnabled,
    tab,
    buttonName,
    isEnabledCSV,
    modalTitle,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div>
      <Button
        id="manifest-view-button"
        aria-label="download-manifest"
        className="default-btn"
        type="primary"
        block
        onClick={() => {
          setIsModalVisible(true);
        }}
        disabled={!isEnabled}
      >
        {buttonName}
      </Button>
      {isModalVisible && (
        <ManifestModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          selectedDate={selectedDate}
          routeId={routeId}
          tab={tab}
          isEnabledCSV={isEnabledCSV}
          modalTitle={modalTitle}
        />
      )}
    </div>
  );
};
ManifestButton.propTypes = {
  routeId: PropTypes.string,
  selectedDate: PropTypes.any,
  isEnabled: PropTypes.bool.isRequired,
};
export default ManifestButton;
