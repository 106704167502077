import { Link } from "react-router-dom";
import moment from "moment/moment";
import { convertDateFormat, formatTo12HourRP } from "../DateFormatter";

export const filterOptions = {
  generateDefaultOptions: (data, filterName) => {
    const uniqueValues = [
      ...new Set(data.map((item) => item[filterName])),
    ].filter((value) => value !== null && value !== undefined && value !== ""); // Filter out empty, null, or undefined values
    return uniqueValues.map((value) => ({ text: value, value }));
  },
  generateDeliveryWindowOptions: (data) => {
    const uniqueValues = [
      ...new Set(
        data.map((item) => {
          const { deliveryWindow } = item;
          if (
            deliveryWindow &&
            deliveryWindow.earliestArrivalDateTime &&
            deliveryWindow.latestArrivalDateTime
          ) {
            const earliestDate = formatTime(
              deliveryWindow.earliestArrivalDateTime
            );
            const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
            return `${earliestDate} - ${latestDate}`;
          }
          return null;
        })
      ),
    ].filter(Boolean);

    return uniqueValues.map((value) => ({
      text: value,
      value,
    }));
  },
};

export const columnRenderers = {
  actions: (column, { url, getAllQueryParams }) => ({
    ...column,
    render: (_, record) => {
      const path = `${url}/details/${record.routeId}`;
      return (
        <Link
          to={{
            pathname: path,
            search: null,
          }}
          state={{
            fromDashboard: true,
            prevQueryParams: getAllQueryParams(),
          }}
        >
          {" "}
          View Details
        </Link>
      );
    },
  }),
  deliveryWindow: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (deliveryWindow) => {
      if (
        deliveryWindow &&
        deliveryWindow.earliestArrivalDateTime &&
        deliveryWindow.latestArrivalDateTime
      ) {
        const earliestDate = formatTime(deliveryWindow.earliestArrivalDateTime);
        const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
        return `${earliestDate} - ${latestDate}`;
      }
      return null;
    },
    onFilter: (value, record) => {
      const { deliveryWindow } = record;
      if (
        deliveryWindow &&
        deliveryWindow.earliestArrivalDateTime &&
        deliveryWindow.latestArrivalDateTime
      ) {
        const earliestDate = formatTime(deliveryWindow.earliestArrivalDateTime);
        const latestDate = formatTime(deliveryWindow.latestArrivalDateTime);
        const combinedValue = `${earliestDate} - ${latestDate}`;
        return combinedValue.indexOf(value) === 0;
      }
      return false;
    },
  }),
  driverNotes: (
    column,
    {
      setIsDrawerOpen,
      setSelectedStopId,
      setDriverNotesOrderNumber,
      setDriverNotesWorkOrderNumber,
    }
  ) => ({
    ...column,
    render: (record) => {
      return (
        <img
          src="/images/Note_icon.svg"
          alt={"DriverNote"}
          data-testid="driver-notes"
          className="driver-notes-icon"
        />
      );
    },
    onCell: (record) => {
      return {
        onClick: () => {
          setIsDrawerOpen(true);
          setSelectedStopId(record.stopId);
          setDriverNotesOrderNumber(record.orderNumber);
          setDriverNotesWorkOrderNumber(record.workOrderNumber);
        },
      };
    },
  }),
  lastUpdatedTimestamp: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (lastUpdatedTimestamp) => {
      if (lastUpdatedTimestamp) {
        const formatedDate = convertDateFormat(
          lastUpdatedTimestamp.split(" ")[0]
        );
        const formatedTime = formatTo12HourRP(
          lastUpdatedTimestamp.split(" ")[1]
        );
        return `${formatedDate}   ${formatedTime}`;
      }
      return null;
    },
  }),
  completionEta: (column, columnConfig) => ({
    ...getDefaultColumnConfig(column, columnConfig),
    render: (completionEta) => {
      return completionEta
        ? formatTo12HourRP(completionEta.split(" ")[1])
        : null;
    },
  }),
};

//TODO: adjust getColumnSorter in sorter story
export const getDefaultColumnConfig = (
  column,
  { filtersOptions, initialSort, initialFilters }
) => ({
  ...column,
  sorter: (a, b) => {
    if (
      column.dataIndex === "deliveryWindow" &&
      a[column.dataIndex] !== undefined
    ) {
      return deliveryWindowSorter(a, b, column);
    } else {
      if (a[column.dataIndex] !== undefined) {
        return a[column.dataIndex].localeCompare(b[column.dataIndex]);
      }
      return null;
    }
  },
  sortOrder: initialSort.field === column.dataIndex ? initialSort.order : null,
  filteredValue: initialFilters[column.dataIndex] || null,
  filters: filtersOptions[column.dataIndex] || [],
  filterSearch: true,
  onFilter: (value, record) => {
    if (record[column.dataIndex] !== undefined) {
      return record[column.dataIndex].indexOf(value) === 0;
    }
  },
  render: (text) => <div style={{ minWidth: column.minWidth }}>{text}</div>,
});

/**
 * Extracts and formats the time from a datetime string.
 *
 * @param {string} datetime - The datetime string to extract the time from.
 * @returns {string} - The formatted time string.
 */
const formatTime = (datetime) => {
  return moment.parseZone(datetime).format("hh:mm A");
};

const formatDeliveryTime = (earlyDateTime, lateDateTime) => {
  const earliestDate = formatTime(earlyDateTime);
  const latestDate = formatTime(lateDateTime);
  return `${earliestDate} - ${latestDate}`;
};

const deliveryWindowSorter = (a, b, column) => {
  return formatDeliveryTime(
    a[column.key].earliestArrivalDateTime,
    a[column.key].latestArrivalDateTime
  ).localeCompare(
    formatDeliveryTime(
      b[column.key].earliestArrivalDateTime,
      b[column.key].latestArrivalDateTime
    )
  );
};

//Determines the RP Row border color as per Delivery Status
export const getRPRowClassName = (record) => {
  return `table-row-${getRPDetailsColor(record.deliveryStatus)}`;
};

const getRPDetailsColor = (deliveryStatus) => {
  if (deliveryStatus !== undefined) {
    switch (deliveryStatus) {
      case "COMPLETED":
        return "green";
      case "EXCEPTION":
      case "MISSED":
        return "red";
      case "PENDING":
      case "ARRIVED":
      case "ENROUTE":
        return "blue";
      default:
        return "blue";
    }
  }
};
